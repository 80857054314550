@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --brands-background: #{$grey10};
    --brand-item-text-color: #{$black};
    --brand-item-background: #{$white};
    --brand-item-radius: 0;
}
.Brands {
    &-Item {
        a {
            font-size: 13px;
            line-height: 17px;
        }
    }
    .AdvoxSlider {
        .slick-prev {
            @include from-tablet {
                left: 0;
            }
            @include widest-desktop {
                left: -20px;
            }
        }
        .slick-next {
            @include from-tablet {
                right: 0;
            }
            @include widest-desktop {
                right: -20px;
            }
        }

        .slick-dots {
            width: 100%;
        }
    }
}
