@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ContractorStructure {
    &-Wrapper {
        margin-bottom: 65px;
    }

    &-TableCell {
        button {
            color: $black;
            font-weight: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 140%;
            > svg {
                width: 16px;
                height: 12px;
                top: 2px;
                margin-right: 8px;
            }
        }
        &_isMobileLayout {
            padding: 3px 23px;
            width: 100%;
            div {
                > span {
                    color: $black;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 140%;
                    margin-right: 16px;
                }
            }
        }

        &_isIcon {
            svg {
                top: 3px;
            }
            @include mobileAndTablet {
                span + svg {
                    top: 2px;
                }
            }
        }
        &_isEditIcon {
            @include mobile {
                margin-top: 10px;
            }
        }
        &.ContractorStructure-TableCell_isMobileLayout.ContractorStructure-TableCell_isEditIcon,
        &.ContractorStructure-TableCell_isMobileLayout.ContractorStructure-TableCell_isRemoveIcon {
            @include tablet {
                position: absolute;
                right: -25px;
                top: 15px;
                text-align: right;
            }
        }

        &.ContractorStructure-TableCell_isMobileLayout.ContractorStructure-TableCell_isEditIcon {
            max-width: 50%;
        }

        &.ContractorStructure-TableCell_isMobileLayout.ContractorStructure-TableCell_isRemoveIcon {
            @include mobile {
                position: absolute;
                right: -25px;
                bottom: 15px;
                text-align: right;
                max-width: 50%;
            }
            @include tablet {
                top: 50px;
                right: -25px;
            }
        }
    }

    &-WrapperHeading {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @include mobile {
            flex-wrap: wrap;
        }

        h4 {
            font-size: 22px;
            font-weight: 700;
            margin: 0 18px 0 0;
        }

        .Field_type_search {
            max-width: 216px;

            @include mobile {
                width: 100%;
                max-width: 100%;
                order: 1;
                margin-top: 15px;
            }

            input {
                --input-padding: 8px 13px 8px 17px;

                &::placeholder {
                    color: $grey3;
                }
            }

            .SearchIcon {
                fill: $default-primary-base-color;
            }
        }

        .Button {
            margin-left: auto;
            width: 187px;

            &_small {
                --button-height: 40px;
                --button-hover-height: 40px;
            }
        }
    }

    &-TableWrapper {
        &,
        > table {
            @include mobileAndTablet {
                width: 100%;
                overflow-x: hidden;
            }
        }
    }

    &-Table {
        &_type {
            &_head {
                width: 100%;

                tr {
                    th,
                    td {
                        &:first-child {
                            @include desktop {
                                width: 25%;
                            }

                            @include tablet {
                                width: 40%;
                            }

                            @include mobile {
                                width: 50%;
                            }
                        }
                    }
                }
            }

            &_department,
            &_cost-centre {
                @include desktop {
                    width: 100%;
                }

                tr {
                    th,
                    td {
                        &:first-child {
                            &:not(.ContractorStructure-TableCell_no-result):not(.ContractorStructure-TableCell_isMobileLayout) {
                                width: 50px;
                                min-width: 50px;
                                padding: 0;
                            }

                            text-align: center;

                            &.ContractorStructure-TableCell_isMobileLayout {
                                position: absolute;
                                left: -10px;
                                top: 15px;
                                width: 30px;
                                min-width: unset;
                            }
                        }
                        &:last-child,
                        &:nth-last-child(2) {
                            &:not(.ContractorStructure-TableCell_no-result):not(.ContractorStructure-TableCell_isMobileLayout) {
                                width: 80px;
                                min-width: 80px;
                                text-align: center;
                                padding: 0;
                            }
                        }
                        &:nth-last-child(3) {
                            &:not(.ContractorStructure-TableCell_no-result):not(.ContractorStructure-TableCell_isMobileLayout) {
                                width: 130px;
                                min-width: 130px;
                            }
                        }
                    }
                }
            }
        }
    }

    .Table-Additional {
        display: none;

        p {
            font-size: 13px;
            font-weight: normal;
            line-height: 17px;
            color: $grey70;
            margin-bottom: 3px;
        }
    }

    &-TableRow {
        &_isActive {
            background: $grey10;

            .Table-Additional {
                display: block;
                padding-top: 15px;
            }

            &.ContractorStructure-TableRow_isMobileLayout .Table-Additional {
                padding-top: 5px;
            }
        }
        &_isMobileLayout {
            display: flex;
            flex-direction: column;
            padding: 15px 25px;
            &:first-child {
                border-top: var(--table-body-border);
            }
            &:last-child {
                border-bottom: var(--table-body-border);
            }
        }
    }
    &-IconLabel {
        color: $black;
        font-weight: 700;
        font-size: 13px;
        line-height: 140%;
        margin-right: 8px;
    }

    .Icon {
        &-Arrow {
            width: 8px;
            height: 8px;
        }

        &-Edit {
            width: 16px;
            height: 16px;

            path {
                fill: var(--primary-base-color);
            }
        }

        &-Remove {
            width: 13px;
            height: 17px;
        }
    }
}
