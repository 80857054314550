@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ShowMoreLessText {
    &-ToggleLink {
        &_type_secondary {
            position: relative;
            display: table;
            width: auto;
            white-space: nowrap;
            margin-top: 20px;
            color: $black;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;

            &::before {
                content: '';
                position: absolute;
                right: -16px;
                top: 7px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 4px 0 4px;
                border-color: $black transparent transparent transparent;
            }

            &::after {
                content: '';
                display: block;
                height: 1px;
                position: absolute;
                bottom: -2px;
                left: 0;
                right: 0;
                background-color: $grey5;
            }

            &.ShowMoreLessText-ToggleLink_isExpanded {
                margin-top: 0;
                &::before {
                    content: '';
                    border-width: 0 4px 4px 4px;
                    border-color: transparent transparent $black transparent;
                }
            }
        }
    }
}
