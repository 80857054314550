@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.BrandsPage {
    min-height: 0;
    margin-bottom: 0;

    @include mobile {
        margin-top: 24px;
    }

    &-Heading {
        font-size: 30px;
        font-weight: 700;
        font-family: $font-primary;
        color: $default-primary-base-color;
        text-align: center;

        @include desktop {
            margin-bottom: 30px;
        }

        @include mobileAndTablet {
            margin-bottom: 28px;
        }

        @include mobile {
            font-size: 24px;
        }
    }

    &-ContentWrapper {
        position: relative;

        &:before {
            content: '';
            height: 100%;
            width: 100vw;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background: var(--brands-background);
        }
    }

    .SeoText {
        margin-top: 55px;
        padding-bottom: 100px;
    }
}
