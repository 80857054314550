@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ContractorStructureRemovePopup {
    .Popup-Content {
        @include desktop {
            max-width: 537px;
        }
    }
    .Popup-Header {
        padding: 0;
    }
    .Button {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: bottom;
        letter-spacing: unset;

        @include desktop {
            width: calc(50% - 12px);
            margin-bottom: 0;
        }

        &:first-of-type {
            @include desktop {
                margin-right: 24px;
            }
        }

        &.Popup-ExtraCloseBtn {
            margin-top: 0;

            svg {
                fill: $white;
            }
            @include mobileAndTablet {
                margin-top: 0;
            }
            &:not(.Button_isHollow) {
                background: $red50;
                border-color: $red50;
            }
            &.Button_isHollow {
                --button-height: 48px;
                --button-hover-height: 48px;
                height: 48px;
            }
        }
    }

    &-Content {
        text-align: center;

        svg {
            width: 55px;
            height: 60px;
        }
    }

    &-ContentHeading {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: $red50;
        margin-bottom: 9px;
        text-transform: unset;
    }

    &-ContentText {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $black;
    }
}
