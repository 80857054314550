@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ContractorStructureCreateOrEditForm {
    &-Label {
        font-size: 15px;
        font-weight: 700;
    }

    &-FieldsBox {
        margin-bottom: 40px;
    }

    &-Limit {
        display: grid;
        grid-gap: 16px;
        align-items: center;
        margin-block-end: 25px;

        @include desktop {
            grid-template-columns: minmax(auto, 160px) 1fr;
        }

        &Label {
            font-size: 15px;
            font-weight: 700;
            line-height: 20px;
        }

        &Button {
            --button-height: 40px;
            --button-hover-height: 40px;
            --popup-botton-background: #{$black};
            margin-top: 0 !important;
        }
    }
}
