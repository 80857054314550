@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --help-overlay-border-color: #{$default-secondary-base-color};
    --help-overlay-box-shadow-color: rgba(0, 0, 0, 0.15);
}

.HelpOverlay {
    @include desktop {
        position: absolute;
        top: calc(100% + 11px);
        left: 14px;
        padding: 35px 32px;
        border-radius: 0 10px 10px 10px;
        background: $white;
        min-width: 80px;
    }

    &_isVisible {
        @include desktop {
            border: 1px solid var(--help-overlay-border-color);
            box-shadow: 0 0 15px 0 var(--help-overlay-box-shadow-color);
            z-index: 2;
        }
    }

    a {
        @include desktop {
            display: flex;
            align-items: center;
            white-space: nowrap;

            svg {
                width: 20px;
                height: 20px;
                margin-right: 20px;
            }
        }
    }

    .CmsBlock-Wrapper {
        @include desktop {
            display: grid;
            grid-row-gap: 30px;
        }
    }
}
