@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --popup-scroll-accent-color: #{$default-secondary-base-color};
}

.SearchOverlay {
    &-Results {
        padding-block-start: 20px;
        padding-block-end: 10px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
        position: absolute;
        width: 100%;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

        ul {
            max-height: 400px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-right: 20px;

            @include mobileAndTablet {
                padding-right: 15px;
            }

            &::-webkit-scrollbar {
                width: 10px;

                @include mobileAndTablet {
                    width: 5px;
                }
            }

            &::-webkit-scrollbar-track,
            &::-webkit-scrollbar-thumb {
                background-clip: padding-box;
            }

            &::-webkit-scrollbar-thumb {
                background-color: var(--popup-scroll-accent-color);
                border-right: 5px solid $white;

                @include mobileAndTablet {
                    border-right: 10px;
                }
            }

            &::-webkit-scrollbar-track {
                background-color: $grey5;
                border-right: 5px solid $white;

                @include mobileAndTablet {
                    border-right: 0;
                }
            }
        }
    }
}
