@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.SearchField {
    @include mobileAndTablet {
        &_isVisible {
            .SearchField {
                &-Input {
                    border: 1.5px solid $blue30;
                }
            }
        }
    }

    @include mobile {
        margin: 0 5px 0 auto;

        &_isVisible {
            top: 9px;
        }
    }

    @include mobileAndTablet {
        &_isVisible {
            position: absolute;
            left: calc(50% - 30px);
            transform: translateX(-50%);
            z-index: 10;

            .SearchField {
                &-SearchInnerWrapper {
                    width: calc(100vw - 100px);
                    margin: auto;
                }

                &-SearchIcon {
                    inset-inline-end: 15px;
                }
            }
        }
    }

    @include tablet {
        &_isVisible {
            left: calc(50% - 70px);
            transform: translateX(-50%);
            top: -3px;

            .SearchField {
                &-SearchInnerWrapper {
                    width: calc(100vw - 175px);
                    .SearchField-Input_isActive {
                        height: 50px;
                    }
                }
            }
        }
    }

    &-Input {
        border-color: $blue30;
    }

    &-SearchIcon {
        svg {
            width: 21px;
            height: 21px;
        }
    }
}
