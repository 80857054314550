@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.Accordion {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
        margin: 0;
    }

    &-Header,
    &-Text,
    &-Box {
        transition: 0.2s ease-in-out;
    }

    &-Header {
        cursor: pointer;
        margin: auto 0;

        &:after {
            width: 6px;
            height: 6px;
            border: solid $default-primary-base-color;
            border-width: 0 2px 2px 0;
            display: inline-block;
            content: '';
            position: absolute;
            top: 10px;
            right: 0;
            transform: rotate(45deg);
        }
    }

    &-Text {
        visibility: hidden;
    }

    &-Box {
        height: 78px;
        padding: 20px 0;
        text-align: left;
        border-bottom: 1px solid rgba(91, 69, 177, 0.1);

        &:first-of-type {
            border-top: 1px solid rgba(91, 69, 177, 0.1);
        }

        .Accordion-Text a {
            font-size: 14px;
            color: $purple80;
            opacity: 0.4;
            line-height: 34px;
            font-family: $font-secondary;
            letter-spacing: -0.2px;
            font-weight: 400;
        }

        &_isOpen {
            height: 100%;

            .Accordion-Text {
                margin-top: 25px;
                margin-bottom: 0;
                visibility: visible;
            }

            .Accordion-Header {
                &:after {
                    transform: rotate(-135deg);
                }
            }
        }
    }
}
