@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.SearchPage {
    &-Heading {
        font-size: 30px;
        color: $default-primary-base-color;
        display: block;
        line-height: 1.4;
        letter-spacing: 0.03em;
        margin-bottom: 15px;

        @include mobileAndTablet {
            margin-top: 25px;
            margin-bottom: 40px;
        }

        @include mobile {
            font-size: 26px;
        }

        &_isNoResults {
            margin-bottom: 0;
        }

        span {
            text-transform: none;
            &.SearchPage {
                &-Title {
                    margin-right: 5px;
                }
                &-SearchWord {
                    color: $default-secondary-base-color;
                    margin-right: 15px;
                }
                &-ProductsNumber {
                    color: $grey4;
                    font-size: 15px;
                    letter-spacing: 0.07em;
                    margin-bottom: 7px;
                    white-space: nowrap;
                }
            }
        }
    }
}
