@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ShoppingListPopup {
    .Popup-Content {
        overflow-y: visible;

        #o_add_new_list {
            font-weight: 700;
            padding-block: 20px;
            margin: 0;
            color: $default-primary-base-color;
            border-top: 1px solid $grey4;
        }
    }

    &-Content {
        margin-top: 20px;
        h6 {
            margin: 0;
            font-weight: 400;
        }
    }

    .Field {
        width: 100%;
        margin-top: 0;
    }

    .Button {
        margin-top: 30px;
    }

    &-newList {
        margin-top: 30px;

        .Field {
            width: 100%;

            input {
                width: 100%;
            }
        }
    }
}
