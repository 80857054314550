@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --rma-row-status-radius: 5px;
    --rma-row-status-bg-default: #{$grey4};
    --rma-status-bg-pending: #ffb800;
    --rma-status-bg-processing: #{$blue50};
    --rma-status-bg-rejected: #{$error-color};
    --rma-status-bg-completed: #{$green50};
}

.RmaList {
    &-Number {
        color: $default-primary-base-color;
        font-weight: 500;
    }

    &-Status {
        color: $white;
        font-family: $font-primary;
        font-weight: bold;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 1px;
        padding: 8px;
        border-radius: var(--rma-row-status-radius);
        background: var(--rma-row-status-bg-default);

        &_type_1 {
            background: var(--rma-status-bg-pending);
        }
        &_type_2 {
            background: var(--rma-status-bg-processing);
        }
        &_type_3 {
            background: var(--rma-status-bg-rejected);
        }
        &_type_4 {
            background: var(--rma-status-bg-completed);
        }
        &_type_5 {
            background: var(--rma-row-status-bg-default);
        }
    }

    table {
        overflow-x: auto;
        display: block;
        width: 100%;
        max-width: calc(100vw - (var(--my-account-wrapper-margin-mobile) * 2));

        @include tablet {
            max-width: calc(100vw - 28px);
        }
        @include desktop {
            width: 700px;
        }
        @include wide-desktop {
            width: 100%;
            display: table;
        }

        thead th {
            border-radius: 0;
            &:first-of-type {
                border-radius: var(--table-head-radius) 0 0 var(--table-head-radius);
            }
            &:last-of-type {
                border-radius: 0 var(--table-head-radius) var(--table-head-radius) 0;
            }
        }

        tbody td {
            a {
                color: $default-secondary-base-color;
                font-weight: 500;
                font-size: 15px;
                line-height: 18px;
                display: flex;
                align-items: center;

                svg {
                    width: 7px;
                    margin-left: 14px;
                }
            }
        }

        tr th,
        tr td {
            &:nth-of-type(4) {
                min-width: 200px;
            }
            &:nth-of-type(5) {
                min-width: 150px;
            }
        }
    }
}
