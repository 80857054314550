@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.Footer {
    &-Bottom {
        @include tablet {
            margin-top: 0;
        }
    }
    &-PaymentIcons {
        justify-content: flex-start;
        border: 0;

        ul {
            svg:not(.Icon_grayscale) {
                filter: grayscale(0);
                opacity: 1;
            }

            svg,
            path,
            rect {
                &:not(.Fill-Unchanging) {
                    fill: $default-primary-base-color;
                }

                &.Fill-Unchanging {
                    fill: $white;
                }
            }
        }
    }
    &-StoreSwitcher {
        display: flex;
        @include mobileAndTablet {
            padding: 0 0 40px;
        }
        @include from-tablet {
            padding: 40px 0;
        }
        @include desktop {
            justify-content: flex-end;
        }
        .StoreSwitcher {
            margin: 0;
        }
    }
    &-StoreSwitcherCopy {
        font-family: $font-primary;
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: $grey3;
        margin-left: 30px;
        margin-bottom: 0;

        @include tablet {
            margin-bottom: 0;
        }
    }

    &_isSimpleFooter {
        .Footer-Content {
            min-height: unset;
        }
        .Footer-Bottom {
            @include desktop {
                padding-top: 25px;
                align-items: flex-start;
            }
        }
        .Footer-StoreSwitcher {
            @include desktop {
                padding: 0;
            }
        }
        .Footer-ContactInfo {
            @include mobile {
                margin-bottom: 15px;
            }

            @include desktop {
                display: flex;
                width: 100%;
                min-width: 80%;
            }
            h5 {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                margin-right: 34px;
            }
            > ul {
                display: flex;
                flex-wrap: wrap;
                text-align: left;
            }
            > ul p,
            > ul p a {
                color: $black;
            }
            > ul li p {
                padding-left: 30px;
                margin-bottom: 3px;
            }
            > ul li div {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: $grey3;
                padding-left: 30px;
                &:last-child {
                    @include mobile {
                        margin-bottom: 20px;
                    }
                }
            }
            > ul li {
                @include from-tablet {
                    margin-right: 85px;
                }
                &:last-child {
                    a {
                        font-weight: normal;
                    }
                    .Footer-Contact-Icon {
                        stroke: unset;
                    }
                }
            }
            .Footer-Contact-Icon {
                top: -4px;

                @include desktop {
                    top: -2px;
                }
            }
        }
    }
}
