@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

[dir='ltr'] .Header {
    &-Nav {
        @include desktop {
            --header-nav-height: 75px;
        }

        @media (max-width: 480px) {
            justify-content: space-between;

            > * {
                margin: 0 !important;
            }
        }

        + .Menu-MenuWrapper {
            @include mobileAndTablet {
                display: none;
            }
        }
    }

    &-Credit {
        @include desktop {
            position: relative;
        }

        &Wrapper {
            width: auto;

            @include desktop {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
            }

            @include tablet {
                margin-left: 10px;
            }

            @include mobile {
                margin-left: 20px;
            }

            &_isActive {
                @include from-tablet {
                    position: relative;
                    z-index: 1000;

                    &:before {
                        height: calc(100% + 10px);
                        top: 0;
                        border-radius: 0;
                        border: 1px solid $blue30;
                        content: '';
                        width: calc(100% + 32px);
                        left: -16px;
                        position: absolute;
                        border-bottom: none;
                        background: $white;
                        box-shadow: 0 -1px 15px -1px var(--header-item-box-shadow-color);
                    }

                    &:after {
                        content: '';
                        width: calc(100% + 32px);
                        height: 25px;
                        top: 100%;
                        left: -15px;
                        position: absolute;
                        background: white;
                    }
                }
            }
        }

        &Title {
            @include desktop {
                font-size: var(--header-icon-title-font-size);
                font-weight: var(--header-icon-title-font-weight);
            }
        }
    }

    &-CreditTitle,
    &-ShoppingListTitle,
    &-CompareTitle,
    &-LoyaltyProgramTitle {
        @include desktop {
            font-size: var(--header-icon-title-font-size);
            font-weight: var(--header-icon-title-font-weight);
            text-align: center;
        }
    }

    &-LoyaltyProgramTitle {
        white-space: break-spaces;
        transform: translateY(25%);
        width: min-content;
    }

    &-MinicartItemCount {
        top: 9px;
        right: -4px;
    }

    &-Button,
    &-MyAccount,
    &-Help,
    &-MinicartButton,
    &-Credit {
        height: calc(var(--header-nav-height) - 10px);
        margin: 0 12px;
        @include mobile {
            margin: 0 5px;
        }

        > button,
        > a,
        > .Header-LoyaltyProgramWrapper {
            white-space: nowrap;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 10px 0 0;
            margin: 0;
            color: $grey-dark;

            > svg {
                margin: 0 auto;
                width: 24px;
                height: 30px;
            }
        }

        &_type_minicart {
            margin-right: 0;
        }
    }

    &-Button,
    &-Help,
    &-MinicartButton {
        &Wrapper {
            @include mobile {
                margin: 0 7px;
            }

            &_isActive {
                @include from-tablet {
                    position: relative;
                    z-index: 1000;

                    &:before {
                        content: '';
                        height: calc(100% + 10px);
                        top: 0;
                        border-radius: 0;
                        border: 1px solid $blue30;
                        width: calc(100% + 32px);
                        left: -16px;
                        position: absolute;
                        border-bottom: none;
                        background: $white;
                        box-shadow: 0 -1px 15px -1px var(--header-item-box-shadow-color);
                    }

                    &:after {
                        content: '';
                        width: calc(100% + 32px);
                        height: 25px;
                        top: 100%;
                        left: -15px;
                        position: absolute;
                        background: $white;
                    }
                }
            }
        }
    }

    &-Help,
    .StoreSwitcher,
    .CurrencySwitcher {
        padding-left: 0;
        @include desktop {
            &:before {
                display: none;
            }
        }
    }

    .StoreSwitcher {
        margin-right: 15px;
        &-Title {
            --store-switcher-color: $black;
        }

        @include tablet {
            margin-left: 10px;
        }
    }

    &-Button_type_menu {
        height: 48px;
        width: 48px;
        border-color: $blue50;
        margin: 0 0 0 10px;

        > p {
            text-transform: uppercase;
        }

        @include mobileAndTablet {
            height: 50px;
            width: auto;
            padding: 0 10px;
            margin-left: 26px;
            line-height: 23px;

            .MobileMenu {
                &-Title {
                    font-size: 13px;
                    font-weight: 700;
                    margin: 0 28px 0 14px;
                }

                &-Icon {
                    margin-left: 0;
                }
            }
        }
    }

    &-SignInButton {
        margin: 0 10px 0 20px;

        &.Button_isMobile {
            background: transparent;
            padding: 0;
            width: auto;
            border: none;
        }
    }

    &-RegisterButton {
        margin: 0 0 0 10px;
    }

    &-LogoWrapper {
        @include mobile {
            --header-logo-height: 40px;
            --header-logo-width: 75px;
        }

        @include tablet {
            --header-logo-width: 100px;
        }
    }

    &-HelpIcon {
        @include mobile {
            margin: 0 20px 0 0;
        }
    }

    &-Minicart {
        &ButtonWrapper {
            margin-left: 15px;
        }
    }

    &-Button {
        &_type_loyalty_program,
        &_type_shoppingList {
            margin-left: 15px;
        }

        &_type_loyalty_program {
            &, svg {
                cursor: default;
            }
        }
    }

    &-MyAccount {
        margin-left: 15px;
    }

    &_name_search {
        @include tablet {
            .Header-Nav {
                justify-content: space-between;
            }
        }
    }
}
