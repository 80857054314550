@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --search-input-desktop-border-color: #{$orange10};
}

.SearchField {
    display: block;

    @include desktop {
        max-width: 705px;
        padding: 0 30px;
    }

    @include mobileAndTablet {
        width: auto;
        margin-left: auto;
        margin-right: 0;
        z-index: 1;

        &_isVisible {
            .SearchField {
                &-Input {
                    border: 1.5px solid var(--search-input-desktop-border-color);
                    padding: 8px 24px;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    @include tablet {
        &_isVisible {
            position: absolute;
            left: var(--content-padding);

            .SearchField {
                &-SearchInnerWrapper {
                    width: calc(100vw - 380px);
                }
            }
        }
    }

    @include mobile {
        &_isVisible {
            position: absolute;
            top: -7px;
            left: calc(50% - 30px);
            transform: translateX(-50%);
            z-index: 10;

            .SearchField {
                &-SearchInnerWrapper {
                    width: calc(100vw - 100px);
                    margin: auto;
                }

                &-SearchIcon {
                    inset-inline-end: 15px;
                }
            }
        }
    }

    &-SearchInnerWrapper {
        @include mobileAndTablet {
            width: 25px;
        }
    }

    &-Input {
        font-size: 15px;
        font-weight: 400;
        background: $white;
        border-color: var(--search-input-desktop-border-color);
        --input-padding: 8px 24px;

        @include mobileAndTablet {
            border: none;
            padding: 0;
            opacity: 0;
            visibility: hidden;
            min-width: 0;
        }
    }

    &-SearchIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobileAndTablet {
            inset-inline-end: 0;
        }

        @include tablet {
            svg {
                width: 22px;
                height: 22px;
            }
        }
    }
}
