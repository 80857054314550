@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --store-switcher-label-color: #{$black};
    --store-switcher-button-color: #{$black};
    --store-switcher-line-background: transparent;
    --store-switcher-color: #{$black};
}

[dir='ltr'] .StoreSwitcher {
    @include mobile {
        margin: 0 16px 0 0;
    }

    &-Title {
        color: var(--store-switcher-color);
        font-weight: 700;
        @include desktop {
            --store-switcher-color: #{$black};
        }
    }

    .StoreItems-Item {
        &:hover {
            background: $blue30;
            color: $white;
        }
    }

    &-StoreList {
        right: unset;
    }
}
