@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

[dir='ltr'] .ImageZoomPopup {
    height: 99%;
    &_isVisible {
        .ProductLabel {
            display: none;
        }
    }
    &.Popup {
        height: 100%;
    }

    &-PopupContent {
        max-height: unset;
        height: 100vh;
        max-width: unset;
        width: 100vw;
        border: none;
        border-radius: 0;

        .Popup-Header {
            padding: 0;
            border: none;
        }
    }

    .ProductGallery {
        height: 100%;

        .ProductGallery-SliderWrapper {
            max-height: 100%;

            .Image {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 100%;
                    width: auto !important;
                    top: unset;
                    left: unset;
                }
            }
        }

        .ProductGallery-Additional {
            display: flex;
            align-items: center;
        }
    }

    .Slider-Arrow {
        display: none;
    }

    .CarouselScroll {
        height: calc(var(--carousel-height) + 40px);
    }
}
