@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.ToggleSwitcher {
    --accent-color: #{$default-secondary-base-color};
    --checkbox-background: #{$white};
    --input-background: #cfcfcf;

    .Field {
        margin-top: 0;
        &-CheckboxLabel {
            position: relative;
            display: inline-block;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .input-control {
                    background-color: var(--accent-color);
                }

                &:focus + .input-control {
                    box-shadow: 0 0 1px var(--accent-color);
                }

                &:checked + .input-control:before {
                    transform: translateX(20px);
                }
            }

            .input-control {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: var(--input-background);
                transition: 0.4s;
                width: 40px;
                height: 20px;
                border: 0;
                &::before {
                    position: absolute;
                    display: block;
                    content: '';
                    height: 12px;
                    width: 12px;
                    left: 4px;
                    bottom: 4px;
                    background-color: var(--checkbox-background);
                    transition: 0.4s;
                }
                &::after {
                    display: none;
                }
            }

            > span {
                display: block;
                margin: 1px 0 0 50px;
                min-width: 100%;
                font-size: 13px;
                color: $grey3;
            }
        }
    }
}
