@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --popup-header-background: #{$white};
    --popup-header-heading-color: #{$black};
    --popup-content-border-color: #{$white};
    --popup-border-radius: 0;
    --popup-scroll-accent-color: #{$default-primary-base-color};
    --popup-close-btn-background: #{$default-primary-base-color};
    --popup-close-btn-color: #{$white};
    --popup-close-btn-border-radius: 0;
    --popup-botton-background: #{$default-primary-base-color};
}

[dir='ltr'] .Popup {
    &-Header {
        border-bottom: none;

        @include mobileAndTablet {
            padding: 12px 15px 12px 21px;
        }

        h3 {
            letter-spacing: 0;
            color: var(--popup-header-heading-color);
        }
    }

    &-SubHeader {
        padding-bottom: 25px;
    }

    .Popup-CloseBtn {
        width: 48px;
        height: 48px;
        background: var(--popup-close-btn-background);
        padding: 0;
        cursor: pointer;

        @include desktop {
            right: -24px;
            top: -24px;
        }

        svg path {
            stroke: none;
        }
    }

    .Button {
        &:not(.Button_isHollow) {
            background: var(--popup-botton-background);
            border-color: var(--popup-botton-background);
        }
        &:not(.Popup-ExtraCloseBtn) {
            width: 100%;
            margin-top: 15px;
        }
    }

    &-ExtraCloseBtn {
        @include desktop {
            width: 100%;
        }
    }
}
