@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --popup-header-background: #{$default-secondary-base-color};
    --popup-header-heading-color: #{$default-primary-base-color};
    --popup-content-border-color: #{$default-secondary-base-color};
    --popup-border-radius: 10px;
    --popup-scroll-accent-color: #{$default-secondary-base-color};
    --popup-close-btn-color: #{$default-primary-base-color};
    --popup-close-btn-border-radius: 10px;
}

.Popup {
    &-Wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    &_isVisible {
        @include mobile {
            top: 0;
            height: 100%;
        }
    }

    &-Content {
        border: 1px solid var(--popup-content-border-color);
        border-radius: var(--popup-border-radius);
        padding: 35px;

        &::-webkit-scrollbar {
            width: 15px;

            @include mobileAndTablet {
                width: 5px;
            }
        }

        &::-webkit-scrollbar-track,
        &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--popup-scroll-accent-color);
            border-right: 10px solid $white;
            @include mobileAndTablet {
                border-right: 0;
            }
        }

        &::-webkit-scrollbar-track {
            background-color: $grey5;
            border-right: 10px solid $white;
            margin-top: 50px;
            margin-bottom: 50px;
            @include mobileAndTablet {
                border-right: 0;
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        @include mobileAndTablet {
            height: 100%;
            max-height: 100vh;
            border-radius: unset;
            border: none;
            padding: 0;
            & > * {
                padding: 0 21px 21px;
            }
        }

        @include mobile {
            & > * {
                padding: 0 16px 16px;
            }
        }
    }

    &-TitleIcon {
        margin-right: 20px;

        @include mobile {
            display: none;
        }
    }

    &-Header {
        padding-bottom: 20px;
        border-bottom: 1px solid $grey5;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @include mobileAndTablet {
            background-color: var(--popup-header-background);
            padding: 12px 28px 12px 25px;
            border-bottom: none;
        }

        @include mobile {
            padding: 12px 13px 12px 20px;
        }

        &_isSubHeader {
            border-bottom: none;
            padding-bottom: 10px;
        }

        h3 {
            font-size: 24px;
            color: var(--popup-header-heading-color);
            font-weight: bold;
            text-transform: none;
            margin-bottom: 0;
            letter-spacing: 0.07em;

            @include mobileAndTablet {
                color: $white;
                font-size: 22px;
            }

            @include mobile {
                font-size: 15px;
            }
        }
    }

    &-SubHeader {
        font-family: $font-secondary;
        font-size: 15px;
        font-weight: 400;
        color: $grey3;
        padding-bottom: 29px;
        margin-bottom: 0;
        border-bottom: 1px solid $grey5;

        @include mobileAndTablet {
            display: none;
        }
    }

    &-ExtraCloseBtn {
        min-width: 136px;
        display: block;
        justify-self: center;
        box-shadow: unset;
        letter-spacing: 1px;
        margin: 41px auto 17px auto;
        line-height: 1;

        @include mobileAndTablet {
            width: calc(100% - 42px);
            margin: 32px 21px;
            padding: 0 21px;
        }

        @include mobile {
            width: calc(100% - 32px);
            margin: 32px 16px;
            padding: 0 16px;
        }
    }
}

[dir='ltr'] {
    .Popup {
        .Popup-CloseBtn {
            width: 20px;
            height: 20px;
            right: -15px;
            top: -15px;

            @include desktop {
                svg path {
                    stroke: var(--popup-close-btn-color);
                }
            }

            @include mobileAndTablet {
                position: static;
                margin-left: auto;
                width: 40px;
                height: 40px;
                background-color: var(--popup-close-btn-color);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: var(--popup-close-btn-border-radius);
            }
        }
    }
}
