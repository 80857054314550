@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --default-notification-height: 40px;
    --notification-info-color: #{$blue50};
    --notification-info-background: #{$white};
    --notification-info-height: var(--default-notification-height);
    --notification-error-color: #{$red50};
    --notification-error-background: #{$white};
    --notification-error-height: var(--default-notification-height);
    --notification-success-color: #{$green50};
    --notification-success-background: #{$white};
    --notification-success-height: var(--default-notification-height);

    --notification-border-radius: 5px;
}
.Notification {
    border: 1px solid var(--notification-color);
    svg {
        fill: var(--notification-color);
    }
}
