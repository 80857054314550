@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.TradeCredit {
    display: grid;
    grid-row-gap: 10px;

    &-Item {
        display: flex;
        font-size: 13px;
        color: $grey3;

        &Label {
            padding-right: 28px;
            margin-right: auto;
            min-width: 130px;
        }

        &Value {
            font-weight: 700;
            color: $black;
            white-space: nowrap;
        }

        &_debt {
            align-items: end;

            .TradeCredit {
                &-ItemValue {
                    color: $red;
                }
            }
        }
    }
}
