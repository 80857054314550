@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

:root {
    --slider-heading-color: #{$black};
}

.AdvoxSlider {
    --slider-arrow-background: #{$white};

    &-Heading {
        text-align: left;
        font-size: 22px;
        line-height: 32px;

        @include from-tablet {
            margin: 10px auto 20px;
        }
    }
    &_variant_hero {
        --slider-height: 485px;
        --slider-height-tablet: 280px;
        --slider-height-desktop: 376px;

        .slick-list {
            @include from-tablet {
                border-radius: 0;
            }
        }

        .slick-arrow {
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0992304);
            --slider-arrow-background: #{$white};

            @include from-tablet {
                top: calc((var(--slider-height-tablet) / 2 + 10px));
            }
            @include desktop {
                top: calc((var(--slider-height-desktop) / 2));
            }
            &.slick-prev {
                @include from-tablet {
                    left: -18px;
                }
                @include widest-desktop {
                    left: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset) + 32px);
                }
            }
            &.slick-next {
                @include from-tablet {
                    right: -18px;
                }

                @include widest-desktop {
                    right: calc((100% - var(--content-wrapper-width)) / 2 - var(--slider-arrow-offset) + 32px);
                }
            }
        }

        .slick-dots {
            @include from-tablet {
                display: none !important;
            }
        }
    }

    .slick-arrow {
        > svg {
            width: 12px;
            height: 14px;

            path {
                stroke: $default-primary-base-color;
            }
        }
        &.slick-prev {
            svg {
                top: 13px;
            }
        }
        &.slick-next {
            svg {
                top: 13px;
                left: 15px;
            }
        }
    }
    .slick-dots {
        li {
            background: $default-primary-base-color;
            width: 6px;
            height: 6px;
            margin: 0 8px;

            @include large-screen {
                margin: 8px 0;
            }

            &.slick-active {
                background: $default-primary-base-color;
            }
        }
    }
}
