@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

@mixin expanded-content {
    opacity: 1;
    max-height: 100%;
}

.ContractorLinks {
    &-Wrapper {
        padding: 32px;
        border: 1px solid $grey30;
        margin: 40px 0;

        & > .Button {
            width: 100%;
        }
    }

    &-Heading {
        font-size: 22px;
        line-height: 29px;
        font-weight: bold;
        margin-bottom: 15px;
    }

    &-Company {
        font-size: 14px;
        line-height: 24px;
        font-weight: normal;
        color: $white;
    }

    .ExpandableContent {
        padding: 20px 0;
        border-bottom: 1px solid $grey6;

        &-Button {
            &_isContentExpanded {
                svg {
                    transform: rotate(-180deg);
                }
            }

            .AddIcon,
            .MinusIcon {
                display: none;
            }
        }

        &-Heading {
            display: flex;
            align-items: center;
            width: 100%;
            padding-right: 20px;

            .Field {
                margin: 0;
            }

            label {
                font-size: 13px;
                font-weight: normal;
                color: $default-primary-base-color;
                cursor: pointer;
            }

            svg {
                margin-left: auto;
                width: 8px;
                height: 4px;

                path {
                    fill: $black;
                }
            }
        }

        &-Content {
            max-height: 0;
            overflow: hidden;
            opacity: 0;

            &_isContentExpanded {
                @include expanded-content;
            }

            p {
                font-size: 13px;
                font-weight: normal;
                color: $grey3;
                margin-bottom: 3px;
            }
        }

        &:last-of-type {
            border-bottom: none;
        }
    }

    &-SuccessInfo {
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
            width: 60px;
            height: 60px;

            path {
                fill: $default-secondary-base-color;
            }
        }

        p {
            font-size: 24px;
            font-weight: bold;
            margin-top: 18px;
            color: $default-secondary-base-color;
        }
    }

    &-Actions {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;
        gap: 24px;

        & > * {
            width: calc(100% / 2 - 12px);
        }
    }

    &-Consortium {
        background: $default-primary-base-color;

        .ContractorLinks-Heading {
            color: $white;
        }
    }

    &-Any {
        background: $grey10;
    }

    &.Popup {
        &-Heading {
            @include mobileAndTablet {
                max-width: calc(100% - 40px);
            }

            @include mobile {
                line-height: 24px;
            }
        }

        &.ContractorLinksPopup-Any {
            .ExpandableContent-Content {
                display: none;

                &_isContentExpanded {
                    display: block;
                }
            }
        }
    }

    .CheckoutAddressBook {
        .Checkout-Heading {
            font-size: 24px;
        }

        &-Wrapper {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 15px;

            table {
                thead {
                    th {
                        padding-right: 35px;
                    }
                }

                td,
                th {
                    font-size: 13px;
                    padding: 10px;
                }

                th {
                    max-width: 125px;
                    min-width: 125px;
                }

                tr {
                    min-width: 100px;
                }
            }
        }
    }
}
