@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.OrderRequests {
    &-Popup {
        font-size: 13px;
        line-height: 17px;
        font-family: var(--font-switzer);
        font-weight: var(--secondary-font-weight-bold);
        text-transform: uppercase;
        white-space: nowrap;
        cursor: pointer;

        .ChevronIcon {
            margin-inline-start: 6px;

            path {
                fill: $black;
            }
        }
    }

    &-Wrapper {
        @include mobile {
            margin-inline: calc(var(--content-padding) * -1);
            max-width: 100vw;

            & > .OrderRequests-Table {
                margin-inline: var(--content-padding);
            }
        }
    }

    &-Table {
        width: 100%;
        margin-block-end: 0;
        table-layout: auto;

        th {
            white-space: nowrap;
        }

        th,
        td {
            &:last-of-type {
                width: 90px;
                min-width: 90px;

                @include mobile {
                    width: 90px;
                    min-width: 90px;
                }
            }
        }

        &:not(&_notFound) {
            td {
                &:first-of-type {
                    color: $blue50;
                }

                &:last-of-type {
                    padding-inline-end: 0;
                    text-align: right;
                }
            }
        }
    }

    &-Range {
        display: flex;
        align-items: center;

        & > span {
            margin-inline: 10px;
        }
    }

    &-PageSize {
        display: flex;
        align-items: center;

        @include mobile {
            margin-top: 30px;
        }

        label {
            font-size: 13px;
        }

        .Field {
            margin-top: 0;
            margin-left: 10px;

            &Select {
                min-width: unset !important;
            }
        }
    }

    &-Pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        align-items: center;

        @include mobile {
            flex-direction: column-reverse;
            align-items: center;
        }

        ul {
            margin: 0;
        }
    }
}
