@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.HeroSlider {
    max-width: var(--slider-max-width);

    &-Wrapper {
        margin: 0 auto;
        padding: 0;
        background: $white;

        @include from-tablet {
            flex-direction: row;
            background: $grey-light;
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);

            > div {
                padding: 50px 0 55px;
            }
        }
        @include desktop {
            min-height: calc(var(--slider-height-desktop) + 55px);
        }
        @include large-screen {
            min-height: unset;
            > div {
                padding: 30px 0;
            }
        }
    }

    &-Slide {
        position: relative;
        white-space: nowrap;
        width: 100%;

        &_clickable {
            cursor: pointer;
        }

        @include desktop {
            display: flex;
            align-items: center;
            max-width: var(--content-wrapper-width);
            padding-left: var(--content-padding);
            padding-right: var(--content-padding);
            margin: 0 auto;
        }

        @include desktop {
            height: var(--slider-height-desktop);
        }
    }

    &-SlideImage {
        width: 100%;
        img {
            object-fit: cover;
            height: var(--slider-height-tablet);
        }
        @include mobile {
            height: var(--slider-height-tablet);
            img {
                height: var(--slider-height-tablet);
            }
        }
        @include tablet {
            height: var(--slider-height-tablet);
        }
        @include desktop {
            height: var(--slider-height-desktop);
            img {
                height: var(--slider-height-desktop);
            }
        }
    }

    &-SlideContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        max-width: 100%;
        position: relative;
        margin: 0;
        top: 0;
        padding: 25px 30px 30px;
        background: $blue50;
        text-align: center;

        @include mobile {
            min-height: 202px;
        }
        @include from-tablet {
            text-align: left;
            max-width: 240px;
            padding: 25px 40px 30px;
        }
        @include desktop {
            min-width: 370px;
            max-width: 422px;
            padding: 25px 59px 20px;
        }
    }

    &-SlideContentText {
        width: 100%;
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 150%;
        color: $grey-dark;
    }

    &-SlideHeader,
    &-SlideDescription {
        white-space: normal;
    }

    &-SlideHeader {
        font-family: $font-primary;
        font-weight: 700;
        line-height: 140%;
        color: $white;
        letter-spacing: 0.03em;
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        line-height: 125%;
        color: $white;
        letter-spacing: -1px;
        z-index: 0;

        @include from-tablet {
            text-align: left;
            font-size: 30px;
            line-height: 40px;
        }
        @include desktop {
            line-height: 54px;
            font-size: 42px;
            max-width: 280px;
        }

        &:before {
            display: none;
        }
    }

    &-SlideDescription {
        font-family: $font-secondary;
        font-size: 15px;
        line-height: 150%;
        margin: 30px auto 0;
        word-wrap: break-word;
        color: $white;

        @include desktop {
            max-width: 465px;
        }
    }

    &-CTAWrapper {
        width: 100%;
    }

    &-CTAButtonWrapper {
        max-width: 100%;
        margin-top: 10px;
        &_position {
            &_center {
                text-align: center;
            }

            &_right {
                text-align: right;
            }
        }
    }

    &-CTAButton {
        white-space: nowrap;
        height: 47px;
        min-width: 197px;
        width: auto;
        box-shadow: none;
        font-family: $font-primary;
        letter-spacing: 0.07em;
        font-weight: bold;
        font-size: 15px;
        line-height: 140%;
        &:hover,
        &:focus {
            background-color: var(--button-hover-background);
            border-color: var(--button-hover-border);
            color: var(--button-hover-color);
        }
        @include from-tablet {
            min-width: 140px;
            margin-top: 20px;
        }
        @include desktop {
            min-width: 197px;
            height: var(--button-height);
        }
    }

    &-SlideContainer {
        width: 100%;

        @include from-tablet {
            display: flex;
            flex-direction: row;
            border-radius: 10px;
            overflow: hidden;

            &_imagePosition_right {
                .HeroSlider {
                    &-SlideImage {
                        order: 2;
                    }

                    &-SlideContent {
                        order: 1;
                    }
                }
            }
        }
    }
}
