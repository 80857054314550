@import '~advox-basic/src/style/abstract/variables';

@import '../../style/abstract/variables';
@import '../../style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../style/abstract/button';

.Field {
    --input-checkbox-radius: 0;
    --checkbox-width: 16px;
    --checkbox-height: 16px;

    &_type {
        &_text,
        &_textarea,
        &_email,
        &_password,
        &_select,
        &_number_withoutButton {
            .Field-LabelContainer {
                top: 19px;
            }

            input:focus,
            textarea:focus {
                & + .Field-LabelContainer {
                    label {
                        --field-label-container-color: #{$default-primary-base-color};
                    }
                }
            }

            &.Field_isValid {
                input,
                textarea {
                    --input-border-color-focus: #{$blue30};
                    --input-background-color: #{$white};
                    --primary-success-color: #{$grey-dark};
                    &:focus {
                        --primary-success-color: #{$blue30};
                    }
                }
            }
        }
    }

    &-ResetButton{
        cursor: pointer;

        &:hover {
            color: $default-primary-base-color;
        }  
    }

    [type='checkbox'] {
        + .input-control {
            border-color: $grey4;

            &:hover {
                @include desktop {
                    &::after {
                        --checkmark-color: #{$default-primary-base-color};
                    }
                }
            }
        }

        &:checked {
            + div.input-control {
                border-color: #{$default-primary-base-color};
                background-color: $default-primary-base-color;
            }
        }
    }

    &_type_file {
        display: flex;
        flex-direction: column;
        input {
            display: none;
        }
        .Field-LabelContainer {
            font-weight: bold;
            font-size: 13px;
            line-height: 17px;
            text-transform: uppercase;
            label {
                cursor: pointer;
                display: flex;
                align-items: center;
                svg {
                    margin-right: 14px;
                }
                span {
                    border-bottom: 1px solid $grey5;
                }
            }
        }
    }
    [type='file'] {
        + label {
            display: block;
            position: static;
            height: unset;
            padding: 0;
            border: 0;
            order: 3;
            p {
                font-size: 13px;
                line-height: 16px;
                color: $grey3;
            }
        }
    }
}
