@mixin button-visible {
    opacity: 1;
    height: 25px;
    width: 25px;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
}

@mixin button-disabled {
    cursor: not-allowed;
    color: var(--button-disabled-color);
    background-color: var(--button-disabled-background);
    border-color: var(--button-disabled-background);
    box-shadow: none;
}

@mixin button {
    align-items: center;
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    cursor: pointer;
    display: inline-flex;
    color: var(--button-color);
    font-size: 13px;
    font-weight: 700;
    justify-content: center;
    height: var(--button-height);
    line-height: normal;
    padding-inline: var(--button-padding);
    text-decoration: var(--button-text-decoration);
    text-transform: uppercase;
    transition-duration: 0.25s;
    transition-timing-function: ease-out;
    transition-property: background-color, color, border;
    will-change: background-color, color, border;
    z-index: 1;
    box-shadow: var(--button-box-shadow);
    font-family: var(--font-switzer);
    letter-spacing: normal;

    @include mobile {
        user-select: none;
        width: 100%;
    }

    border: {
        color: var(--button-border);
        style: solid;
        width: var(--button-border-width);
    }

    &:hover,
    &:focus {
        text-decoration: var(--button-hover-text-decoration);
    }

    &:not([disabled]) {
        &:hover,
        &:focus {
            @include desktop {
                background-color: var(--button-hover-background);
                border-color: var(--button-hover-border);
                border-radius: var(--button-hover-border-radius);
                color: var(--button-hover-color);
                height: var(--button-hover-height);
                padding-inline: var(--button-hover-padding);
            }
        }
    }

    &[disabled] {
        @include button-disabled();
    }

    &_isTertiary {
        background-color: var(--tertiary-button-background);
        border-color: var(--tertiary-button-border);
        border-radius: var(--tertiary-button-border-radius);
        color: var(--tertiary-button-color);
        height: var(--tertiary-button-height);
        padding-inline: var(--tertiary-button-padding);
        text-decoration: var(--tertiary-button-text-decoration);
        box-shadow: none;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include desktop {
                    background-color: var(--tertiary-button-hover-background);
                    border-color: var(--tertiary-button-hover-border);
                    border-radius: var(--tertiary-button-hover-border-radius);
                    color: var(--tertiary-button-hover-color);
                    height: var(--tertiary-button-hover-height);
                    padding-inline: var(--tertiary-button-hover-padding);
                    text-decoration: var(--tertiary-button-hover-text-decoration);
                }
            }
        }
    }
    &_isQuartiary {
        --button-border: #{$black};
        --button-background: #{$black};
        --button-color: #{$white};
        font-size: 13px;
        line-height: 17px;
        letter-spacing: unset;

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include desktop {
                    background-color: $black;
                    border-color: $black;
                    border-radius: 0;
                }
            }
        }
    }
    &_isHollow {
        background-color: var(--hollow-button-background);
        border-color: var(--hollow-button-border);
        border-radius: var(--hollow-button-border-radius);
        color: var(--hollow-button-color);
        height: var(--hollow-button-height);
        padding-inline: var(--hollow-button-padding);
        text-decoration: var(--hollow-button-text-decoration);
        box-shadow: none;
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include desktop {
                    background-color: var(--hollow-button-hover-background);
                    border-color: var(--hollow-button-hover-border);
                    border-radius: var(--hollow-button-hover-border-radius);
                    color: var(--hollow-button-hover-color);
                    height: var(--hollow-button-hover-height);
                    padding-inline: var(--hollow-button-hover-padding);
                    text-decoration: var(--hollow-button-hover-text-decoration);
                }
            }
        }
    }
    &_isWithoutBorder {
        border: 0;
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;
        box-shadow: none;

        &:hover,
        &:focus {
            text-decoration: underline;
            padding: 0;
        }
    }
    &_medium {
        --button-height: 48px;
        --button-hover-height: 48px;
        --button-border-radius: 0;
        --button-hover-border-radius: var(--button-border-radius);
    }
    &_small {
        --button-height: var(--button-height-small);
        --button-hover-height: var(--button-hover-height-small);
    }

    &_secondary {
        background-color: var(--secondary-button-background);
        border-color: var(--secondary-button-border);
        box-shadow: var(--secondary-button-box-shadow);
        &:not([disabled]) {
            &:hover,
            &:focus {
                @include desktop {
                    background-color: var(--secondary-button-hover-background);
                    border-color: var(--secondary-button-hover-border);
                }
            }
        }
    }

    // make icons on buttons have same hover behaviour as buttons themselves
    svg {
        pointer-events: none;
    }
}
