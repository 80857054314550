@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

:root {
    --menu-border-radius: 10px;

    --menu-tablet-width: 360px;
    --menu-mobile-width: 260px;

    --menu-item-caption-color: #{$default-primary-base-color};
    --menu-item-caption-parent-color: #{$default-primary-base-color};
    --menu-item-caption-parent-hover-color: #{$orange50};
    --menu-item-caption-parent-mobile-background: #{$default-secondary-base-color};
    --menu-item-caption-special-color: #{$orange50};
    --menu-item-caption-parent-mobile-color: #{$default-primary-base-color};

    --subcategory-item-color: #{$default-primary-base-color};
    --subcategory-item-active-background: #{$purple};
    --subcategory-column-border-color: #{$purple};
    --subcategory-border-color: #{$orange50};
    --subcategory-box-shadow-color: rgba(0, 0, 0, 0.15);

    --menu-overlay-background: #{$black};
    --menu-overlay-opacity: 0.4;
}

.Menu {
    &-Item {
        @include desktop {
            height: auto;
            display: flex;
            margin: 0 25px;
            padding: 0;

            &:last-child {
                margin-bottom: 0;
            }

            &_isHovered {
                &:before {
                    content: '';
                    background: $white;
                    position: absolute;
                    top: -5px;
                    left: -25px;
                    width: calc(100% + 50px);
                    height: calc(100% + 20px);
                    inset-inline-start: -25px;
                    padding-inline-end: 0;
                    border-radius: var(--menu-border-radius) var(--menu-border-radius) 0 0;
                    box-shadow: 0 0 15px 0 var(--subcategory-box-shadow-color);
                    z-index: 1;
                    border: 1px solid var(--subcategory-border-color);
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -24px;
                    border-radius: var(--menu-border-radius);
                    background: $white;
                    width: calc(100% + 50px);
                    height: calc(100% + 35px);
                    z-index: 3;
                }
            }
        }

        @include mobileAndTablet {
            height: auto;
            margin-bottom: 0;

            &:not(:first-of-type) {
                margin-inline-start: 0;
            }
        }

        .ChevronIcon {
            fill: $default-primary-base-color;

            @include desktop {
                margin-left: 5px;
            }

            @include mobileAndTablet {
                width: 14px;
            }
        }

        &_center {
            @include desktop {
                .Menu-SubCategoriesWrapper {
                    left: -25px;
                    border-radius: var(--menu-border-radius);
                    transform: translateX(-50%);
                }
            }
        }

        &_right {
            @include desktop {
                .Menu-SubCategoriesWrapper {
                    left: auto;
                    right: -27px;
                    border-radius: var(--menu-border-radius) 0 var(--menu-border-radius) var(--menu-border-radius);
                }

                .Menu-SubCategoryBanner {
                    display: none;
                }
            }
        }
    }

    &-ItemList {
        @include desktop {
            display: inline-flex;
        }

        @include mobileAndTablet {
            display: block;
        }

        &_type {
            &_main {
                @include desktop {
                    .Menu-Link {
                        display: flex;
                        margin: 0;
                        line-height: inherit;
                        --button-height: 48px;
                        --button-hover-height: 48px;
                        cursor: pointer;

                        &_isHovered {
                            z-index: 4;
                        }

                        &_isParent {
                            height: var(--button-height);

                            .Menu-ItemCaption {
                                color: var(--menu-item-caption-parent-color);
                            }

                            .ChevronIcon {
                                fill: var(--menu-item-caption-parent-color);
                            }

                            &.Menu-Link_isHovered {
                                .Menu-ItemCaption {
                                    color: var(--menu-item-caption-parent-hover-color);
                                }

                                .ChevronIcon {
                                    fill: var(--menu-item-caption-parent-hover-color);
                                    transform: rotate(-90deg);
                                }
                            }
                        }
                    }

                    .Menu-ItemCaption {
                        display: flex;
                        align-items: center;
                    }

                    .Menu-ExpandedState {
                        position: static;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    &-Link {
        @include desktop {
            font-size: 16px;
        }

        @include mobileAndTablet {
            &.Button {
                display: block;
                margin: 10px 20px;
                width: auto;
                height: auto;

                .Menu-ItemCaption {
                    padding: 9px 10px;
                    justify-content: center;
                    color: $white;
                }
            }
        }
    }

    &-MenuWrapper {
        @include desktop {
            position: relative;
            max-width: var(--content-wrapper-width);
            margin-top: 0;
            padding: 10px var(--content-padding) 20px var(--content-padding);
        }

        @include mobileAndTablet {
            margin-top: 0;
        }
    }

    &-MainCategories {
        margin: 0;

        & > div {
            @include desktop {
                height: auto !important;
                overflow: unset !important;
            }
        }

        @include desktop {
            width: calc(100% + 25px);
            transform: translateX(-25px);
            z-index: 1;
        }

        @include tablet {
            width: var(--menu-tablet-width);
        }

        @include mobile {
            width: var(--menu-mobile-width);
        }
    }

    &-SubCategoriesWrapper {
        border: 1px solid var(--subcategory-border-color);

        @include desktop {
            position: absolute;
            top: calc(100% + 10px);
            left: -25px;
            z-index: 3;
            background: $white;
            border-radius: 0 var(--menu-border-radius) var(--menu-border-radius) var(--menu-border-radius);

            box-shadow: 0 5px 50px var(--subcategory-box-shadow-color);
            padding: 32px 7px 25px;

            .Menu-CategoryList,
            .Menu-SubCategoryList {
                max-height: calc(100vh - var(--header-total-height) - 57px);
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 5px;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: var(--subcategory-item-color);
                    border-right: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                &::-webkit-scrollbar-track {
                    background-color: var(--subcategory-item-active-background);
                    border-right: 0;
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-SubCategories {
        @include desktop {
            padding: 0;
        }

        .Menu-Link {
            @include desktop {
                display: block;
                width: 100%;
                margin: 0;

                &_isHovered {
                    .Menu-ItemCaption {
                        background: var(--subcategory-item-active-background);
                    }
                }
            }
        }

        .Menu-ItemCaption {
            @include desktop {
                font-weight: normal;
                display: flex;
                justify-content: space-between;
                border-radius: 25px;
                padding: 9px 15px;
                margin: 0;
                color: var(--subcategory-item-color);

                &:hover {
                    background: var(--subcategory-item-active-background);
                }
            }
        }

        .Menu-ExpandedState {
            @include desktop {
                display: block;
                position: static;

                svg {
                    fill: var(--subcategory-item-color);
                }
            }
        }
    }

    &-ExpandedState {
        @include mobileAndTablet {
            display: flex;
            position: static;
        }
    }

    &-ItemCaption {
        color: var(--menu-item-caption-color);
        white-space: initial;

        &_special {
            color: var(--menu-item-caption-special-color);
        }

        @include mobileAndTablet {
            font-size: 15px;
            font-weight: 400;
            padding: 15px 30px;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
        }

        &_type_main {
            text-transform: initial;

            @include desktop {
                margin: 0;
            }

            @include mobileAndTablet {
                font-size: 16px;
                font-weight: 600;

                &.Menu-ItemCaption_isParent {
                    color: var(--menu-item-caption-parent-mobile-color);
                    cursor: pointer;
                }

                &.Menu-ItemCaption_isExpanded {
                    flex-direction: row-reverse;
                    justify-content: flex-end;

                    .Menu-ExpandedState {
                        margin-right: 10px;
                    }
                }
            }
        }

        &_type_subcategory {
            &.Menu-ItemCaption_isExpanded {
                @include mobileAndTablet {
                    position: fixed;
                    top: 0;
                    background: var(--menu-item-caption-parent-mobile-background);
                    height: 60px;
                    margin: 0;
                    color: $white;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    padding: 10px 20px;
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;
                    width: 100%;
                }

                @include tablet {
                    max-width: var(--menu-tablet-width);
                }

                @include mobile {
                    max-width: var(--menu-mobile-width);
                }

                .ChevronIcon {
                    @include mobileAndTablet {
                        fill: $white;
                        transform: rotate(180deg);
                        height: 20px;
                        width: 20px;
                        margin-right: 10px;
                    }
                }

                & ~ .Menu-SubMenu_isVisible {
                    @include mobileAndTablet {
                        position: fixed;
                        top: 60px;
                        background: white;
                        z-index: 3;
                        height: 100%;
                    }

                    @include tablet {
                        width: var(--menu-tablet-width);
                    }

                    @include mobile {
                        width: var(--menu-mobile-width);
                    }
                }
            }
        }
    }

    &-Sub {
        &ItemWrapper {
            @include desktop {
                display: flex;

                .Menu-Link {
                    font-weight: 400;
                }
            }
        }

        &Menu {
            @include desktop {
                display: flex;

                .Menu-CategoryList,
                .Menu-SubCategoryList,
                .Menu-SubCategoryBanner {
                    width: 276px;
                    padding: 0 10px;
                }

                .Menu-SubCategoryList,
                .Menu-SubCategoryBanner {
                    border-left: 1px solid var(--subcategory-column-border-color);
                }

                .Menu-SubCategoryBanner {
                    .Image {
                        width: 135%;
                    }
                }

                .Menu-SubCategoryBannerTitle {
                    font-size: 15px;
                    font-weight: 400;
                    color: var(--menu-item-caption-color);
                }
            }

            @include mobileAndTablet {
                display: none;

                &_isVisible {
                    padding-inline: 0;
                    background: none;
                    display: block;
                    padding-bottom: 10px;
                }
            }

            .Menu-ItemList {
                @include desktop {
                    display: flex;
                    flex-direction: column;
                }

                @include mobileAndTablet {
                    columns: auto;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            .Menu-ItemCaption {
                @include mobileAndTablet {
                    font-size: 15px;
                    padding: 15px 30px;

                    &_isExpanded {
                        font-size: 16px;
                        padding: 15px 10px;
                    }
                }
            }
        }

        &MenuButton {
            margin: 10px 20px;
            width: calc(100% - 40px);
            display: grid;
            grid-template-columns: 20px auto;
            grid-gap: 10px;
            align-items: center;
            height: auto;
            min-height: var(--button-height-small);

            .Icon {
                &-Wrapper {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    background: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    & + span {
                        line-height: 1.5;
                    }
                }
            }

            svg {
                width: 12px;
                height: 12px;

                path {
                    fill: $default-primary-base-color;
                }
            }
        }
    }

    &-HiddenCategories {
        @include desktop {
            .Menu-ItemCaption {
                white-space: nowrap;
                margin: 0 45px 10px;
            }
        }
    }

    &-Overlay {
        @include desktop {
            pointer-events: none;
            position: absolute;
            top: 100%;
            left: 50%;
            height: 100vh;
            width: 100vw;
            transform: translateX(-50%);
            display: block;
            background: var(--menu-overlay-background);
            opacity: var(--menu-overlay-opacity);
        }
    }

    &-AdditionalInformation {
        .StoreSwitcher,
        .CurrencySwitcher {
            @include mobileAndTablet {
                margin: 10px 30px;

                .FieldSelect {
                    min-width: auto;
                }

                .FieldSelect-Select {
                    padding: 10px 32px;
                }
            }
        }
    }

    &-CompareLink,
    &-WishlistLink {
        @include mobileAndTablet {
            display: flex;
            align-items: center;
            padding: 10px 30px;
            font-size: 15px;
            font-weight: 600;
            color: var(--menu-item-caption-color);
        }

        &Wrapper {
            @include mobileAndTablet {
                padding: 0;
                margin: 5px 0;
                border: none;
            }
        }

        .Menu-Icon {
            @include mobileAndTablet {
                width: 20px;
                height: 20px;
                margin-right: 10px;
            }
        }
    }
}

[dir='ltr'] {
    .Menu {
        &-CompareLink,
        &-WishlistLink {
            .Header-ItemsCount {
                @include mobileAndTablet {
                    position: static;
                    margin-left: 10px;
                }

                &_isSmall {
                    font-size: 10px;
                    padding: 9px;
                }
            }
        }
    }
}
