@import '~advox-basic/src/style/abstract/variables';

@import '../../../../advox-strigo-b2b/src/style/abstract/variables';
@import '../../../../advox-strigo-b2b/src/style/abstract/mixins';

@import '~advox-basic/src/style/abstract/media';
@import '~advox-basic/src/style/abstract/placeholder';

@import '~@scandipwa/scandipwa/src/style/abstract/loader';
@import '~@scandipwa/scandipwa/src/style/abstract/icons';
@import '~@scandipwa/scandipwa/src/style/abstract/parts';

@import '../../../../advox-strigo-b2b/src/style/abstract/button';

.AnnouncementNotice {
    --demo-notice-height: auto;
    padding-top: env(safe-area-inset-top);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-primary;
    position: fixed;
    top: 0;
    width: 100%;
    height: var(--demo-notice-height);
    z-index: 100;
    padding: 8.5px var(--content-padding);

    &-Content {
        width: 100%;
        text-align: center;
        padding: 0 12px;
        height: auto;
        letter-spacing: -0.4px;
        max-width: var(--content-wrapper-width);

        @include mobile {
            overflow-wrap: break-word;
        }

        a {
            font-weight: bold;
            color: inherit;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }

        strong,
        span,
        p {
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            color: inherit;
            margin: 0;
        }

        img {
            width: auto;
            height: auto;
        }
    }

    &-CloseIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 3.5px;
        right: 5px;
    }
}
